/*
 * @Author: ringo
 * @Date: 2021-08-31 15:25:46
 * @LastEditTime: 2021-12-10 16:30:11
 * @LastEditors: your name
 * @Description:
 * @FilePath: \foundao_work\xiangan_enterprise\src\models\index.ts
 */
// 全局状态

type action = {
  payload: object | undefined;
  type: string;
};
const token = localStorage.getItem('token');
let userInfo = {};
let userInfoString = localStorage.getItem('userInfo');
if (userInfoString) {
  userInfo = JSON.parse(userInfoString);
}
let commonEnum = localStorage.getItem('commonEnum');
if (commonEnum) {
  commonEnum = JSON.parse(commonEnum);
}
let enterpriseInfo = localStorage.getItem('enterpriseInfo');
if (enterpriseInfo) {
  enterpriseInfo = JSON.parse(enterpriseInfo);
}
export default {
  namespace: 'index',
  state: {
    is_login: token ? true : false,
    userInfo: userInfo || {},
    commonEnum: commonEnum || {},
    enterpriseInfo: enterpriseInfo || {},
    stat_loading: false,
  },
  reducers: {
    changeLoginStatus: (state: Object, { payload: status }: action) => {
      console.log(status, '登录状态');
      return {
        ...state,
        is_login: status,
      };
    },
    updateState: (state: Object, { payload, type }: action) => {
      console.log('执行', payload);
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {},
  subscriptions: {}, // 订阅数据源
};
