/*
 * @Author: ringo
 * @Date: 2021-10-15 14:19:37
 * @LastEditTime: 2023-04-21 16:18:27
 * @LastEditors: ringo
 * @Description:
 * @FilePath: \xiangan_work\xiangan-enterprise\src\app.tsx
 */
// 运行时配置 参考文档
import { message } from 'antd';
import React from 'react';
import { RequestConfig, history, getDvaApp } from 'umi';
import RequestProvider from './requestProvider';
import errorHandler from './utils/httpErrorHandle';

// 根节点包裹
export function rootContainer(container) {
  return React.createElement(RequestProvider, null, container);
}

// 请求全局配置
export const request: RequestConfig = {
  prefix: '',
  timeout: 60000,
  // 错误处理
  errorHandler,
  errorConfig: {
    /**
     * 错误数据适配器
     * 使用useRequest时 errorConfig 与 中间件 无法透传
     * 仅在使用 umi自带request时生效
     *
     */
    adaptor: (resData) => {
      // console.log(resData, '返回适配');
      return {
        ...resData,
        success: resData.ok,
        errorMessage: resData.message,
      };
    },
  },
  // 中间件
  middlewares: [],
  // 全局请求拦截器
  requestInterceptors: [
    (url, options) => {
      // console.log(url, options, '请求拦截器');
      const token: string | null = localStorage
        .getItem('token')
        ?.replaceAll('"', '');
      options.headers = {
        ...options.headers,
        Authorization: token,
        client: 'PC',
        // connection: 'keep-alive'
      };
      return {
        url,
        options,
      };
    },
  ],
  /**
   * 全局返回拦截器
   * 执行顺序优先于RequestConfig中的错误处理
   * 如果http状态码不为200则进入错误处理
   * 如果为200直接在拦截器中判断data中的code进行自定义错误处理
   */
  responseInterceptors: [
    async (response, options) => {
      // console.log(response, '返回拦截器');
      /**
       * http 错误状态处理
       * 不为200 进入errorHandler处理
       */
      const { status, statusText, url } = response;
      if (status !== 200) {
        return response;
      } else {
        const data = await response.clone().json();

        const { code } = data;
        switch (code) {
          case 200:
            return Promise.resolve(data);
          case 300:
            // 信息错误 交于页面处理
            console.log('接口', '信息已存在', code, data);
            return Promise.resolve(data);
          case -99:
            console.log('接口', '接口无权限', code, data);
            message.error(data.message || '接口无权限');
            return Promise.reject('接口无权限');
          case -1:
            console.log('接口', '请求错误', code, data);
            message.error(data.message || '请求失败，请稍后再试');
            return Promise.reject(data);
          case 0:
            console.log('接口', '登录失效', code, data);
            message.error(data.message || '登录失效');
            const dva = getDvaApp();
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            dva._store.dispatch({
              type: 'index/changeLoginStatus',
              payload: false,
            });
            history.replace('/login');
            return Promise.reject('登录失效');
          default:
            console.log('接口', '未知错误code', code, data);
            message.error('接口', data.message || '未知错误code');
            Promise.reject(undefined);
        }

        // 登录失效返回全部信息 至全局接口完成处做处理 因为http状态为401故进http错误处理
        // if (data?.code == 0) {
        //   message.error('登录失效');
        //   localStorage.removeItem('userInfo')
        //   localStorage.removeItem('token')
        //   history.replace('/login')
        //   return response
        // }
      }
      return response;
    },
  ],
};

// 动态添加路由
// export function patchRoutes({ routes }) {
//   // merge(routes, extraRoutes);
//   console.log(routes, 'routes');
//   let extraRoutes=[];
//   // 获取另一个地方保存的路由数据数据
//   extraRoutes = window.localStorage.getItem('routes')
//     ? JSON.parse(window.localStorage.getItem('routes'))
//     : [];

//   //  格式化路由信息
//   extraRoutes = extraRoutes.map((item) => {
//     /* item={
//        path：'' // 跳转路径
//        component：'' //组件路径
//      } */
//     return {
//       ...item,
//       // 添加 component  item.component='/login'
//       component: require('./pages' + item.component).default,
//     };
//   });
//   // 添加路由
//   routes.push(...extraRoutes);
//   console.log(routes, extraRoutes);
// }

// export function render(oldRender) {
//   oldRender();
//   // 保存方法 oldRender,oldRender在调用后会使得patchRoutes重新调用
//   window.oldRender = oldRender;
// }
